export default [
  { field: 'relevante', label: 'Relevante', type: 'custom' },
  { field: 'lido', label: 'Lida', type: 'custom' },
  { field: 'remetente', label: 'Remetente' },
  {
    field: 'assunto', label: 'Assunto', editable: true, evento: null,
  },
  { field: 'enviadoEm', label: 'Data de envio' },
  {
    label: 'Consultado em', field: 'consultadoEm',
  },
]
